import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/firestore";
import { allowEmails } from "../common";
import { SwipeSharp } from "@mui/icons-material";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY, //'AIzaSy CUbfGmp1M_tjKRLsvsLdME-l4PneXj0bA',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.app = app.app();
    this.auth = app.auth();
    // this.db = app.database();
    this.functions = app.functions();
    this.firedb = app.firestore();
    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();

    this.userSubscription = null;
    this.userSubscriptionFlag = null;

    this.products = [];
    this.productsFlag = null;
  }

  // *** Auth API ***

  doCratePortalLink = () =>
    this.functions.httpsCallable(
      process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_ENDPOINT
    );

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  signInWithCustomToken = (token) => this.auth.signInWithCustomToken(token);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => {
    this.auth.signOut();
    window.location = "/";
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: "https://accounts.blanchestories.com/account", //process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  doEmailUpdate = (email) => this.auth.currentUser.updateEmail(email);

  doGetUser = async (uid) => await this.firedb.collection("users").doc(uid).get();

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        await this.getUserSubscription(authUser.uid);

        authUser = {
          uid: authUser.uid,
          email: authUser.email,
          emailVerified: authUser.emailVerified,
          providerData: authUser.providerData,
        };

        next(authUser);
      } else {
        fallback();
      }
    });

  /**
   * Get current user
   * @param {*} uid current user's firebse id
   */
  getUserSubscription = async (uid) => {
    if (!this.userSubscription && !this.userSubscriptionFlag) {
      this.userSubscriptionFlag = "loading";

      const user = (await this.doGetUser(uid)).data();
      // console.log('user data', user)
      if (!user || !user.isSubscriber) {
        this.userSubscriptionFlag = "loaded";
        return;
      }

      if (!user.subscriptionId) {
        const subscriptions = await this.firedb
          .collection("customers")
          .doc(uid)
          .collection("subscriptions")
          .where("status", "in", ["active", "trialing"])
          .orderBy("created")
          .limitToLast(1)
          .get();

        if (!subscriptions.empty) {
          const subscriptionDoc = subscriptions.docs[0];

          // get latest invoice
          const latestInvoice = await subscriptionDoc.ref
            .collection("invoices")
            .orderBy("created")
            .limitToLast(1)
            .get();

          const subscriptionData = subscriptionDoc.data();
          this.userSubscription = {
            ...subscriptionData,
            subscriptionId: subscriptionDoc.id,
            current_period_end:
              subscriptionData.current_period_end?.seconds,
            current_period_start:
              subscriptionData.current_period_start?.seconds,
            trial_end: subscriptionData.trial_end?.seconds,
            invoice: latestInvoice.empty ? null : latestInvoice.docs[0]?.data(),
          };
        }
      } else {
        const headers = new Headers()
        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')

        // Create PaymentIntent as soon as the page loads
        await fetch(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "stripeApi/getSubscription", {
          // mode: 'no-cors',
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            id: 'sub_1PiyOCFha9hdCHBgmmRjlI2M',
          }),
        })
          .then((res) => {
            if (res.ok === false) {
              return Promise.reject(res)
            }
            return res.json()
          })
          .then(async subscription => {
            console.log('subscription', subscription)

            const items = subscription.items.data;
            for (let index = 0; index < items.length; index++) {
              const productId = items[index].price.product;
              const productSnap = await this.firedb
                .collection("products")
                .doc(productId)
                .get();

              if (productSnap.exists) {
                const product = productSnap.data();
                items[index].price.product = product;
              }
            }

            this.userSubscription = {
              ...subscription,
              subscriptionId: subscription.id,
              items: items,
              invoice: typeof subscription.latest_invoice == 'string' ? null : subscription.latest_invoice,
            };
          })
          .catch(error => console.error('Error got during fetch subscription from stripe', error))
      }

      this.userSubscriptionFlag = "loaded";
    }
  };

  /**
   *
   * @returns
   */
  getProducts = async () => {
    if (!this.productsFlag) {
      this.productsFlag = "loading";

      const querySnapshot = await this.firedb
        .collection("products")
        .where("active", "==", true)
        .get();

      if (!querySnapshot.empty) {
        await Promise.all(
          querySnapshot.docs.map(async (productDoc) => {
            const product = [productDoc.id, productDoc.data()];

            const priceSnap = await productDoc.ref.collection("prices").get();
            if (!priceSnap.empty) {
              await Promise.all(
                priceSnap.docs.map(async (price) => {
                  product[1].prices = {
                    id: price.id,
                    data: price.data(),
                  };
                })
              );
            }

            this.products.push(product);
          })
        );

        // get products
        const monthly = this.products[0];
        const yearly = this.products[1];
        const quaterly = this.products[3];

        this.products = this.products.filter(([, productData], index) => {
          if (index === 0 || index === 1 || index === 3) return false;

          if (allowEmails.indexOf(this.auth.currentUser?.email) === -1) {
            const name = productData.name.toLowerCase();
            // console.log('----', !name.includes('test'))
            return !name.includes("test");
          }
          return true;
        });

        this.products = [yearly, quaterly, monthly].concat(this.products);
        this.productsFlag = "loaded";
      }
    }
    return this.products;
  };

  // *** User API ***

  //user = async uid => await this.firedb.collection('users').doc(uid).get();

  // user = async (uid) => {
  //   try {
  //     const documentSnapshot = await this.firedb
  //       .collection('users')
  //       .doc(uid)
  //       .get();

  //     const userData = documentSnapshot.data();
  //     return userData;
  //     //setUser(userData);
  //   } catch {
  //     //do whatever
  //   }
  // }, [uid];

  //user = uid => this.db.ref(`customers/${uid}`);
  //users = () => this.db.ref('customers');
}

export default Firebase;
